<template>
  <div class="layout-footer">
    <img alt="Logo" :src="publicPath + footerImage()" height="50" />
    <p class="font-medium">
      Powered by <a href="https://codefuente.com/es">Codefuente</a>
    </p>
    <p class="font-medium flex align-items-center justify-content-center"><i class="pi pi-envelope pr-2"></i> <a :href="'mailto:' + email"> {{ email }} </a></p>
    <p class="font-medium ml-2">Copyright © {{ getYear() }} ‐ acuta.me</p>
  </div>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      publicPath: process.env.BASE_URL,
      email: process.env.VUE_APP_SUPPORT_EMAIL,
    };
  },
  methods: {
    footerImage() {
      return this.$appState.darkTheme
        ? 'images/logo-white.svg'
        : 'images/logo-dark.svg';
    },
    getYear() {
      return new Date().getFullYear();
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>

<template>
  <Dropdown
    v-model="selectedCountry"
    @change="handleInput"
    :options="countries"
    :inputId="id"
    optionLabel="name"
    :filterFields="['name', 'code']"
    :filter="true"
    placeholder="Seleccione un pais"
  >
    <template #value="slotProps">
      <div class="country-item country-item-value" v-if="slotProps.value">
        <img crossorigin="anonymous" :src="`https://countryflagsapi.com/png/${slotProps.value.code}`" />
        <div>{{ slotProps.value.name }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div class="country-item">
        <img
          crossorigin="anonymous"
          :src="`https://countryflagsapi.com/png/${slotProps.option.code}`"
        />
        <div>{{ slotProps.option.name }}</div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import countryList from '../constants/countryList';

export default {
  props: ['id', 'value'],
  updated() {
    if (this.value !== this.selectedCountry) this.selectedCountry = this.value;
  },
  data() {
    return {
      selectedCountry: this.value,
      countries: countryList,
    };
  },
  methods: {
    handleInput() {
      this.$emit('input', this.selectedCountry);
    },
  },
};
</script>

<style lang="scss" scoped>
.country-item {
  display: flex;
  align-items: center;
  img {
    height: 17.3px;
    width: 2rem;
    margin-right: 0.5rem;
  }
}
</style>

<template>
  <div class="grid" style="gap: 1em">
    <div class="field-checkbox h-full col m-auto">
      <Checkbox v-model="enabled" :id="'enabled-' + dayName" :binary="true" />
      <label :for="'enabled-' + dayName">{{ dayLabels[dayName].full }}</label>
    </div>
    <div class="col">
      <Calendar
        :showIcon="true"
        :showTime="true"
        :timeOnly="true"
        v-model="workingHoursStart"
      ></Calendar>
      <small class="p-invalid" v-if="this.error.start">Campo obligatorio</small>
    </div>
    <div class="col">
      <Calendar
        :showIcon="true"
        :showTime="true"
        :timeOnly="true"
        v-model="workingHoursEnd"
      ></Calendar>
    </div>
    <small class="p-invalid" v-if="this.error.end">Campo obligatorio</small>
  </div>
</template>

<script>
export default {
  props: ['day', 'dayName'],
  emits: ['updateStart', 'updateEnd', 'updateEnabled'],
  data() {
    return {
      workingHoursStart: this.day.workingHoursStart,
      workingHoursEnd: this.day.workingHoursEnd,
      enabled: this.day.enabled,
      dayLabels: {
        monday: {
          short: 'Lun',
          full: 'Lunes',
        },
        tuesday: {
          short: 'Mar',
          full: 'Martes',
        },
        wednesday: {
          short: 'Mié',
          full: 'Miércoles',
        },
        thursday: {
          short: 'Jue',
          full: 'Jueves',
        },
        friday: {
          short: 'Vie',
          full: 'Viernes',
        },
        saturday: {
          short: 'Sáb',
          full: 'Sábado',
        },
        sunday: {
          short: 'Dom',
          full: 'Domingo',
        },
      },
      error: {},
    };
  },
  watch: {
    workingHoursStart(newStart) {
      this.validateStart();
      this.$emit('updateStart', newStart);
    },
    workingHoursEnd(newEnd) {
      this.validateEnd();
      this.$emit('updateEnd', newEnd);
    },
    enabled(newEnabled) {
      this.$emit('updateEnabled', newEnabled);
    },
  },
  methods: {
    validateStart() {
      this.error.start = this.workingHoursStart === null;
    },
    validateEnd() {
      this.error.end = this.workingHoursEnd === null;
    },
  },
};
</script>

<style></style>

<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <img alt="Logo" :src="publicPath + topbarImage()" />
      <span>acuta.me</span>
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li>
          <button class="p-link layout-topbar-button" aria-label="Ajustes de usuario" @click="toggle">
            <i class="pi pi-user"></i>
            <span>Perfil</span>
            <TieredMenu ref="menu" :model="items" :popup="true" />
          </button>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
			return {
        publicPath: process.env.BASE_URL,
				items: [
          {
            label:'Cuenta',
            icon:'pi pi-inbox',
            command:() => this.$router.push("/account")
          },
          {
            label:'Salir',
            icon:'pi pi-sign-out',
            command:() => {
              this.$store.commit("logout");
              this.$router.push("/login");
            }
          },
        ]					
      }
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo-dark.svg";
    },
    onLogout() {
      this.$store.commit("logout");
      this.$router.push("/login");
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>

import { DateTime } from 'luxon';

export const formatDateForApi = (date) =>
  DateTime.fromJSDate(date).toISO().split('.')[0];

/**
 *
 * Formats a js date to a time string. It ignores Year, month & day values
 *
 * @param {Date} date - Date to format
 * @returns {string} js string with the format for acutame API
 */
export const formatTimeForApi = (date) => {
  return DateTime.fromJSDate(date).toFormat('HH:mm:ss');
};

/**
 *
 * Parses a time string to a js date. It assumes today as the day
 *
 * @param {string} apiTime time from the api in HH:mm:ss:zzz format
 * @returns {Date} js date object with parsed time from api
 */
export const parseTimeFromApi = (apiTime) => {
  const today = DateTime.now();

  const time = apiTime.split('.');
  const [hours, mins, seconds] = time[0].split(':');

  let millis = 0;
  if (time.length > 0) millis = time[1];

  const parsedDate = today.set({
    hour: hours,
    minute: mins,
    second: seconds,
    millisecond: millis,
  });
  return parsedDate.toJSDate();
};

/**
 *
 * Parses a date string to a js date.
 *
 * @param {string} apiDate date from the api in YYYY-MM-dd
 * @returns {Date} js date object with parsed date from api
 */
export const parseDateFromApi = (apiDate) => {
  const date = DateTime.fromFormat(apiDate, 'yyyy-MM-dd');
  return date.toJSDate();
};

/**
 *
 * Formats an api datetime string to a human friendly format
 *
 * @param {string} apiDate raw dateTime string from api
 * @returns {string} formatted string
 */
export const formatApiDateTimeForHuman = (apiDate) => {
  const date = DateTime.fromISO(apiDate, 'yyyy-MM-ddT');
  return date.toLocaleString(DateTime.DATETIME_FULL);
};

/**
 *
 * Formats an api datetime string to a human friendly format without TimeZone
 *
 * @param {string} apiDate raw dateTime string from api
 * @returns {string} formatted string
 */
export const formatApiDateTimeForHumanWithoutTimeZone = (apiDate) => {
  const date = DateTime.fromISO(apiDate, 'yyyy-MM-ddT');
  return date.toLocaleString({
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
  });
};


export const formatApiDateForHuman = (apiDate) => {
  const date = DateTime.fromISO(apiDate, 'yyyy-MM-ddT');
  return date.toLocaleString({
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
}

/**
 *
 * @param {Date} date
 * @param {number} daysToAdd
 * @returns
 */
export const addDays = (date, daysToAdd) => {
  return DateTime.fromJSDate(date).plus({ days: daysToAdd }).toJSDate();
};

/**
 *
 * @param {Date} date
 * @param {Number} minutes
 * @returns {Date}
 */
export const addMinutes = (date, minutes) =>
  DateTime.fromJSDate(date).plus({ minutes }).toJSDate();

/**
 *
 * @param {Date} date
 * @returns {String} dayAndMonth
 */
export const formatDateOnlyDayAndMonth = (date) => {
  let day = DateTime.fromJSDate(date).day + '';
  let month = DateTime.fromJSDate(date).month + '';

  if (day < 10) day = '0' + day;

  if (month < 10) month = '0' + month;

  const dayAndMonth = day + '-' + month;
  return dayAndMonth;
};

const parseMonthDay = (dateString) => {
  let newDate = DateTime.now();
  const day = dateString.substring(0, 2);
  const month = dateString.substring(3, 5);
  return newDate.set({ day: parseInt(day), month: parseInt(month) });
};

/**
 *
 * @param {Date} date
 * @returns {Date} newDate in JS format
 */
export const parseMonthDayFromApi = (date) => {
  return parseMonthDay(date).toJSDate();
};

/**
 *
 * Parse two dates with api format (dd-mm) and asume the first one is always after the second one
 *
 * @param {String} start
 * @param {String} end
 * @returns {Date[]} array containing period start and end
 */
export const parseMonthDayRangeFromApi = (start, end) => {
  const periodStart = parseMonthDay(start);
  let periodEnd = parseMonthDay(end);
  if (periodStart > periodEnd) periodEnd = periodEnd.plus({ years: 1 });
  return [periodStart.toJSDate(), periodEnd.toJSDate()];
};

/**
 * 
 * Get days till a given date from now
 * 
 * @param {DateTime} date minuend
 * @returns {number}
 */
export const getDaysLeft = (date) => {
  return Math.ceil(date.diff(DateTime.now(), 'days').toObject().days);
}
export const generateRandomId = () => Math.floor(Math.random() * Date.now());

/**
 *
 * Waits for all promises to resolve and returns the value of the slowest one.
 *
 * @param {Promise<T>[]} promises - Array of promises
 * @returns {Promise<T>} value of the last resolved promise
 */
export const getLastResult = async (promises) => {
  if (!promises.length) throw new RangeError('No last result from no promises');
  const results = [];
  await Promise.all(
    promises.map((p) =>
      p.then((v) => {
        results.push(v);
      })
    )
  );
  return results[results.length - 1];
};

export const formWidgetSnippet = (baseUrl, organizationId) =>
  `<iframe style="border: 0px; background-color: transparent;" src="${baseUrl}/public/widget?organization=${organizationId}" width="100%" height="800" frameborder="0"></iframe>`;

export const round = (number, decimals = 0) =>
  Math.round(number * 10 ** decimals) / 10 ** decimals;

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

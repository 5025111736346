<template>
  <div>
    <Button type="button" label="Añade un dato dinámico" @click="toggle" />
    <OverlayPanel ref="op">
      <Listbox
        @change="onKeywordSelect"
        :options="keywords"
        optionLabel="name"
        style="width: 15rem"
      />
    </OverlayPanel>
  </div>
</template>

<script>
export default {
  props: ["keywords"],
  emits: ["onKeywordSelect"],
  methods: {
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    onKeywordSelect(event) {
      this.$refs.op.hide();
      this.$emit("onKeywordSelect", event.value);
    },
  },
};
</script>

<style>
</style>
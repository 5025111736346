export const NOTIFICATION_KEYS = {
  CREATE_EMAIL_MANUAL: {
    type: 'CREATE_BOOKING_MANUAL',
    method: 'EMAIL',
  },
  CREATE_SMS_MANUAL: {
    type: 'CREATE_BOOKING_MANUAL',
    method: 'SMS',
  },
  CREATE_EMAIL_AUTO: {
    type: 'CREATE_BOOKING_AUTO',
    method: 'EMAIL',
  },
  CREATE_SMS_AUTO: {
    type: 'CREATE_BOOKING_AUTO',
    method: 'SMS',
  },
  ACCEPT_EMAIL: {
    type: 'ACCEPT_BOOKING',
    method: 'EMAIL',
  },
  ACCEPT_SMS: {
    type: 'ACCEPT_BOOKING',
    method: 'SMS',
  },
  ACCEPT_EMAIL_MANUAL: {
    type: 'ACCEPT_BOOKING_MANUAL',
    method: 'EMAIL',
  },
  ACCEPT_SMS_MANUAL: {
    type: 'ACCEPT_BOOKING_MANUAL',
    method: 'SMS',
  },
  REJECT_EMAIL: {
    type: 'REJECT_BOOKING',
    method: 'EMAIL',
  },
  REJECT_SMS: {
    type: 'REJECT_BOOKING',
    method: 'SMS',
  },
  CANCEL_EMAIL: {
    type: 'CANCEL_BOOKING',
    method: 'EMAIL',
  },
  CANCEL_SMS: {
    type: 'CANCEL_BOOKING',
    method: 'SMS',
  },
  REMIND_EMAIL: {
    type: 'REMIND_BOOKING',
    method: 'EMAIL',
  },
  REMIND_SMS: {
    type: 'REMIND_BOOKING',
    method: 'SMS',
  },
};

export const CUSTOM_FORM_FIELD_TYPES = {
  CALENDAR: 'BookingCalendar',
  TEXT: 'BookingTextField',
  DROPDOWN: 'BookingDropdown',
  TEXTAREA: 'BookingTextArea',
};

export const BOOKING_LIST_KEYS = {
  bookingDate: 'DATE',
  createdDate: 'CREATED_DATE',
  bookingCode: 'CODE',
  state: 'STATE',
  'customFields.firstname': 'FIRST_NAME',
  'customFields.lastname': 'LAST_NAME',
  'customFields.phone': 'PHONE',
  source: 'SOURCE',
  hasBeenCharged: 'HAS_BEEN_CHARGED',
};

export const BOOKING_STATE_LABELS = {
  PENDING: 'Pendiente',
  ACCEPTED: 'Aceptada',
  REJECTED: 'Rechazada',
  CANCELLED: 'Cancelada',
  COMPLETED: 'Completada',
};

export const BOOKING_STATE_CHIPS = {
  PENDING: 'pending',
  ACCEPTED: 'accepted',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  COMPLETED: 'completed',
};

export const TRIAL_STATE = {
  INACTIVE: 'INACTIVE',
  ACTIVE: 'ACTIVE',
  FIRST_REMINDER: 'FIRST_REMINDER',
  SECOND_REMINDER: 'SECOND_REMINDER',
  EXPIRED: 'EXPIRED',
};

export const NOTIFICATION_LABEL = [
  {
    label: 'Reserva creada por punto de venta',
    key: 'CREATE_BOOKING_MANUAL',
  },
  {
    label: 'Reserva creada por cliente',
    key: 'CREATE_BOOKING_AUTO',
  },
  {
    label: 'Reserva aceptada',
    key: 'ACCEPT_BOOKING',
  },
  {
    label: 'Reserva manual aceptada',
    key: 'ACCEPT_BOOKING_MANUAL',
  },
  {
    label: 'Reserva rechazada',
    key: 'REJECT_BOOKING',
  },
  {
    label: 'Reserva cancelada',
    key: 'CANCEL_BOOKING',
  },
  {
    label: 'Recordatorio de reserva',
    key: 'REMIND_BOOKING',
  },
];

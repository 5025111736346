import api from '../api/Acutame';
import { formatDateForApi } from '../utils/dates';

export default class OrganizationService {
  async getOrganizationList() {
    return api.getMerchantOrganizations();
  }

  async getOrganization(organizationId) {
    return api.getOrganization(organizationId);
  }

  async getConfig(organizationId) {
    return api.getOrganizationConfig(organizationId);
  }

  async getFormFields(organizationId) {
    return api.getFormFields(organizationId);
  }

  async getCustomerByPhone(organizationId, phone, dialCode) {
    return api.getCustomerByPhone(organizationId, phone, dialCode);
  }

  async searchCustomer(organazitionId, blacklisted, search, page, size){
    return api.searchCustomer(organazitionId, search, blacklisted, page, size);
  }

  async updateCustomerBlacklist(organizationId, customerId, blacklisted) {
    const blacklistUpdateBody = {[customerId]: blacklisted};
    return api.updateCustomerBlacklist(organizationId, blacklistUpdateBody);
  }

  async updateCustomCss(organizationId, customCss) {
    return api.putCustomCss(organizationId, { customCss });
  }

  async putAutoaccept(organizationId, newConfigDto) {
    return api.putAutoaccept(organizationId, newConfigDto);
  }

  async putAdvanceTime(organizationId, time) {
    return api.putAdvanceBookingTime(organizationId, time);
  }

  async putCancelTime(organizationId, time) {
    return api.putCancelBookingTime(organizationId, time);
  }

  async createFormField(organazitionId, formField) {
    return api.createFormField(organazitionId, formField);
  }

  async updateFormField(organazitionId, fieldName, formField) {
    return api.putFormField(organazitionId, fieldName, formField);
  }

  async deleteFormField(organazitionId, fieldName) {
    return api.deleteFormField(organazitionId, fieldName);
  }

  async putHolidays(organizationId, newHolidays) {
    return api.putHolidays(organizationId, newHolidays);
  }

  async putBookingLimit(organizationId, newBookingLimitConfig) {
     return api.putBookingLimit(organizationId, newBookingLimitConfig);
  }

  async putNotificationsConfig(organizationId, notificationConfig) {
    return api.putNotificationsConfig(organizationId, notificationConfig);
  }

  async putReminders(organizationId, reminders) {
    return api.putReminders(organizationId, reminders);
  }

  async putAvailabilityConfig(organizationId, availabilityConfig, seasons = []) {
    return api.putAvailabilityConfig(organizationId, {repeatingAvailabilityConfig: availabilityConfig, seasons});
  }

  async putOrganizationConfig(organazitionId, organizationConfig) {
    organizationConfig.phone = organizationConfig.phone.replaceAll(' ', '');
    return api.putOrganizationConfig(organazitionId, organizationConfig);
  }

  async uploadLogo(organazitionId, logo) {
    return api.uploadOrganizationLogo(organazitionId, logo);
  }

  async getTemplateKeywords() {
    return api.getTemplateKeywords();
  }

  async addFullDay(organazitionId, fullDay) {
    const fullDayStr = formatDateForApi(fullDay);
    return api.addFullDays(organazitionId, fullDayStr);
  }

  async removeFullDay(organazitionId, dayStr) {
    return api.removeFullDays(organazitionId, dayStr);
  }

  async putAmountPerDiner(organizationId, amountPerDiner) {
    return api.putCancellationPolicyAmountPerDiner(organizationId, amountPerDiner);
  }

  async putWarningText(organizationId, warningText) {
    return api.putCancellationPolicyWarningText(organizationId, warningText);
  }
  async putEnableCancellationPolicy(organizationId, enableCancellationPolicy) {
    return api.putCancellationPolicyEnabled(organizationId, enableCancellationPolicy);
  }
}

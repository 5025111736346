<template>
  <Message v-if="disabledOrgId" severity="warn" :closable="false">
    <span
      >El punto de venta está desactivado. Para poder seguir gestionando
      reservas actívelo
      <router-link :to="`/organization/${disabledOrgId}/config/organization#toggle-organization-btn`"
        >aquí</router-link
      >.
    </span>
  </Message>
  <Message v-if="showTrialBanner" severity="warn">
    <span v-if="showTrialExpired">Su periodo de prueba ha terminado. </span>
    <span v-else-if="showSecondReminder">
      Su periodo de prueba acabará en menos de <strong>24 horas. </strong>.
    </span>
    <span v-else-if="showFirstReminder">
      Su periodo de prueba acabará en menos de <strong>72 horas. </strong>
    </span>
    <span v-else-if="showTrialMessage"
      >Le quedan {{ remainingDays }} días de prueba.
    </span>
    <router-link to="/pricing">Puede activar su suscripción aquí.</router-link>
  </Message>
  <Message
    v-for="alert of alerts"
    :severity="alert.severity"
    :key="alert.message"
    @close="dismissAlert(alert)"
    >{{ alert.message }}</Message
  >
</template>

<script>
import { TRIAL_STATE } from './constants/constants';
import api from './api/Acutame';
import { getDaysLeft } from './utils/dates';
import { DateTime } from 'luxon';

export default {
  data() {
    return {
      hideTrialBanner: false,
      alerts: this.$store.state.user.alerts.map((alert) => ({
        ...alert,
        severity: alert.severity.toLowerCase(),
      })),
    };
  },
  methods: {
    async dismissAlert(alert) {
      if (!alert?.id) return;
      console.log('Dismissing alert');
      const { id } = alert;
      api.dismissAlert(id);
    },
  },
  computed: {
    disabledOrgId() {
      const organizations = this.$store.state?.user?.organizations;
      const id = this.$route.params.id;
      if (!organizations || !id) return null;
      const disabledOrgIds = organizations
        .filter((org) => !org.enabled)
        .map((org) => org.id);
      if (disabledOrgIds.includes(id)) return id;
      return null;
    },
    remainingDays() {
      return getDaysLeft(DateTime.fromISO(this.$store.state.user.disableDate));
    },
    showTrialBanner() {
      return (
        this.$store.state.user.trialState &&
        this.$store.state.user.trialState !== TRIAL_STATE.INACTIVE
      );
    },
    showTrialExpired() {
      return this.$store.state.user.trialState === TRIAL_STATE.EXPIRED;
    },
    showSecondReminder() {
      return this.$store.state.user.trialState === TRIAL_STATE.SECOND_REMINDER;
    },
    showFirstReminder() {
      return this.$store.state.user.trialState === TRIAL_STATE.FIRST_REMINDER;
    },
    showTrialMessage() {
      return this.$store.state.user.trialState === TRIAL_STATE.ACTIVE;
    },
  },
};
</script>

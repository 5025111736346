<template>
  <div class="wrapper" v-if="show">
    <ProgressSpinner v-if="loading" />
    <template v-else>
      <Listbox
        v-model="selectedCustomer"
        v-if="customers.length"
        :options="customers"
        optionLabel="phone"
        listStyle="max-height:min(200px, 80vh);"
      >
        <template #option="slotProps">
          <div :class="{'flex': true, 'justify-content-between':true, 'custommer-warning': slotProps.option.blacklisted}">
            <span>
              <i v-if="slotProps.option.blacklisted" class="pi pi-exclamation-triangle mr-3" />
              {{ slotProps.option.firstname }}</span>
            <span>{{ slotProps.option.email }}</span>
            <span
              >+{{ slotProps.option.phoneExtension }}
              {{ slotProps.option.phone }}</span
            >
          </div>
        </template>
      </Listbox>
      <div v-else class="p-3">No se han encontrado clientes</div>
    </template>
  </div>
</template>

<script>
import OrganizationService from '../service/OrganizationService';
import * as _ from 'lodash';

export default {
  created() {
    this.organizationService = new OrganizationService();
  },
  mounted() {
    if (this.dialCode) this.getCustomers();
  },
  data() {
    return {
      loading: true,
      customers: [],
      organizationService: null,
      selectedCustomer: null,
    };
  },
  methods: {
    getCustomers: _.debounce(async function () {
      this.loading = true;
      const customers = await this.organizationService.getCustomerByPhone(
        this.organizationId,
        this.phone,
        this.dialCode
      );
      this.customers = customers;
      this.loading = false;
    }, 250),
    onCustomerSelect(event) {
      console.log('test');
      console.log(event);
    },
  },
  watch: {
    phone() {
      this.getCustomers();
    },
    dialCode() {
      this.getCustomers();
    },
    selectedCustomer() {
      if (this.selectedCustomer) this.$emit('onChange', this.selectedCustomer);
    },
  },
  emits: ['onChange'],
  props: ['show', 'organizationId', 'phone', 'dialCode'],
};
</script>

<style scoped lang="scss">
.wrapper {
  position: absolute;
  top: 2.2em;
  left: 0;
  z-index: 999;
  background: white;
  width: 100%;
  border: solid 1px lightgrey;
  border-radius: 0.5em;
  max-height: min(200px, 80vh);
}
.custommer-warning {
  color: red;
}
</style>

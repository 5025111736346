import { createRouter, createWebHashHistory } from 'vue-router';
import authStore from './store/authStore';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/Login.vue'),
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('./pages/recovery/Recovery.vue'),
  },
  {
    path: '/confirmed',
    name: 'confirmed',
    component: () => import('./pages/ConfirmedEmail.vue'),
  },
  {
    path: '/newpassword',
    name: 'changePassword',
    component: () => import('./pages/recovery/ChangePassword.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import('./pages/Signup.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('./pages/Account.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('./pages/Dashboard.vue'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import('./pages/Pricing.vue'),
  },
  {
    path: '/payment/success',
    name: 'paid',
    component: () => import('./pages/PaymentSuccess.vue'),
  },
  {
    path: '/payment/update/:paymentId',
    name: 'updatepayment',
    component: () => import('./pages/PaymentUpdate.vue'),
  },
  {
    path: '/payment/cancelled',
    name: 'unpaid',
    component: () => import('./pages/PaymentCancelled.vue'),
  },
  {
    path: '/pricing/sms',
    name: 'buy_sms',
    component: () => import('./pages/SmsPricing.vue'),
  },
  {
    path: '/organization/new',
    name: 'createOrganization',
    component: () => import('./pages/CreateOrganization.vue'),
  },
  {
    path: '/organization/:id',
    name: 'organization',
    component: () => import('./pages/Organization.vue'),
  },
  {
    path: '/organization/:id/notification/:type',
    component: () => import('./pages/Notification/index.vue'),
  },
  {
    path: '/organization/:id/widget',
    component: () => import('./pages/Widget.vue'),
  },
  {
    path: '/organization/:id/history',
    component: () => import('./pages/NotificationHistory.vue'),
  },
  {
    path: '/organization/:id/config',
    component: () => import('./pages/Configuration/index.vue'),
    children: [
      {
        path: 'notifications',
        component: () =>
          import('./pages/Configuration/NotificationConfiguration.vue'),
      },
      {
        path: 'calendar',
        component: () => import('./pages/Configuration/Schedule.vue'),
      },
      {
        path: 'form',
        component: () => import('./pages/Configuration/FormConfiguration.vue'),
      },
      {
        path: 'organization',
        component: () => import('./pages/Configuration/Organization.vue'),
      },
      {
        path: 'cancellationpolicy',
        component: () => import('./pages/Configuration/CancellationPolicy.vue'),
      },
    ],
  },
  {
    path: '/settings',
    component: () => import('./pages/Organization.vue'),
  },
  {
    path: '/menu',
    component: () => import('./components/MenuDemo.vue'),
    children: [
      {
        path: '',
        component: () => import('./components/menu/PersonalDemo.vue'),
      },
      {
        path: '/menu/seat',
        component: () => import('./components/menu/SeatDemo.vue'),
      },
      {
        path: '/menu/payment',
        component: () => import('./components/menu/PaymentDemo.vue'),
      },
      {
        path: '/menu/confirmation',
        component: () => import('./components/menu/ConfirmationDemo.vue'),
      },
    ],
  },
  {
    path: '/organization/:id/blacklist',
    component: () => import('./pages/Blacklist.vue'),
  }
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
  
    if (to.hash) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({ el: to.hash })
        }, 300)
      })
    }
  
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 })
      }, 300)
    })
  }
});

const redirectToLogin = (route) => {
  return {
    name: 'login',
    replace: true,
    query: { redirectFrom: route.fullPath },
  };
};

const redirectToHome = () => {
  return { name: 'home', replace: true };
};

// Routes for UNATHORIZED USERS
const AUTHENTICATION_ROUTES = ['signup', 'login', 'recovery', 'changePassword'];
//Routes for all users
const PUBLIC_ROUTES = ['confirmed'];

router.beforeEach(async (to) => {
  let userIsAuthenticated = authStore.state.authenticated;

  if (!authStore.state.isInitialized) {
    userIsAuthenticated = await authStore.dispatch('initialize');
  }

  if (PUBLIC_ROUTES.includes(to.name)) return true;

  const isAuthenticationRoute = AUTHENTICATION_ROUTES.includes(to.name);
  if (!userIsAuthenticated && !isAuthenticationRoute) {
    return redirectToLogin(to);
  }

  if (isAuthenticationRoute && userIsAuthenticated) {
    return redirectToHome(to);
  }

  return true;
});

export default router;

<template>
  <Dropdown
    v-model="selectedTimezone"
    @change="handleInput"
    :options="timezones"
    :inputId="id"
    optionLabel="name"
    :filterFields="['name', 'code']"
    :filter="true"
    placeholder="Selecciona un huso horario"
  >
    <template #value="slotProps">
      <div v-if="slotProps.value">
        <div>{{ slotProps.value.name?.replace('_', ' ') }}</div>
      </div>
      <span v-else>
        {{ slotProps.placeholder }}
      </span>
    </template>
    <template #option="slotProps">
      <div>
        <div>{{ slotProps.option.name.replace('_', ' ') }}</div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import timezoneList from '../constants/timezoneList';

export default {
  props: ['id', 'value'],
  updated() {
    if (this.value !== this.selectedTimezone)
      this.selectedTimezone = this.value;
  },
  data() {
    return {
      selectedTimezone: this.value,
      timezones: timezoneList,
    };
  },
  methods: {
    handleInput() {
      this.$emit('input', this.selectedTimezone ?? null);
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div class="col-12 lg:col-6 flex justify-content-between">
    <div>
      <h4>Horarios por días</h4>
      <div
        class="flex flex-column"
        v-for="(config, day) in availability.repeatingAvailabilityConfig"
        :key="day"
        style="margin-bottom: 1em"
      >
        <TimePicker
          :dayName="day"
          :day="config"
          @updateStart="updateStartHandler($event, day)"
          @updateEnd="updateEndHandler($event, day)"
          @updateEnabled="updateEnabledHandler($event, day)"
        />
        <small
          class="p-invalid"
          v-if="
            this.availability.repeatingAvailabilityConfig[day].workingHoursStart >
            this.availability.repeatingAvailabilityConfig[day].workingHoursEnd
          "
          >La hora de inicio debe ser menor que la final.</small
        >
      </div>
    </div>
    <Divider layout="vertical" class="divider-wrapper" />
  </div>
  <div class="col-12 lg:col-6">
    <Divider class="divider-wrapper-mobile hidden" />
    <h4>Descansos</h4>
    <div class="flex" style="gap: 1em">
      <div class="flex flex-column">
        <label for="breakStart" class="mb-2">Comienzo del descanso</label>
        <Calendar
          id="breakStart"
          :showIcon="true"
          :showTime="true"
          :timeOnly="true"
          v-model="this.availability.breakStart"
        ></Calendar>
      </div>
      <div class="flex flex-column">
        <label for="breakEnd" class="mb-2">Final del descanso</label>
        <Calendar
          id="breakEnd"
          :showIcon="true"
          :showTime="true"
          :timeOnly="true"
          v-model="this.availability.breakEnd"
        ></Calendar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['modelValue'],
  data() {
    return {
      availability: this.modelValue,
      breakStart: null,
      breakEnd: null,
    };
  },
  watch: {
    value() {
      this.$emit('input', this.availability);
    },
  },
  methods: {
    updateStartHandler(newStart, day) {
      this.availability.repeatingAvailabilityConfig[day].workingHoursStart = newStart;
    },
    updateEndHandler(newEnd, day) {
      this.availability.repeatingAvailabilityConfig[day].workingHoursEnd = newEnd;
    },
    updateEnabledHandler(newEnabled, day) {
      this.availability.repeatingAvailabilityConfig[day].enabled = newEnabled;
    },
  },
};
</script>

<style></style>

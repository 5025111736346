import NoTokenError from '../errors/NoTokenError';

const TOKEN_KEY = 'TOKEN';

export const setToken = (token, stayLogged) => {
  const storageType = stayLogged ? localStorage : sessionStorage;
  storageType.setItem(TOKEN_KEY, token);
};

export const getToken = () => {
  const token =
    localStorage.getItem(TOKEN_KEY) ?? sessionStorage.getItem(TOKEN_KEY);
  if (token === null) throw new NoTokenError();
  return token;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  sessionStorage.removeItem(TOKEN_KEY);
};

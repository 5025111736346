<template>
  <div
    class="surface-card p-4 shadow-2 border-round w-full lg:w-6"
    style="margin: auto"
  >
    <div class="text-center mb-5">
      <img
        :src="`${publicPath}images/logo-white.svg`"
        alt="Image"
        height="100"
      />
      <div class="text-900 text-3xl font-medium mb-3">Bienvenido de vuelta</div>
      <span class="text-600 font-medium line-height-3"
        >¿No tienes una cuenta?</span
      >
      <router-link
        class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
        to="/signup"
        >¡Créala hoy!</router-link
      >
    </div>

    <div>
      <label for="email1" class="block text-900 font-medium mb-2">Email</label>
      <InputText id="email1" v-model="email" type="text" class="w-full mb-3" />

      <label for="password1" class="block text-900 font-medium mb-2"
        >Contraseña</label
      >
      <InputText
        id="password1"
        v-model="password"
        type="password"
        class="w-full mb-3"
      />
      <div class="flex align-items-center justify-content-between mb-6">
        <span class="p-invalid" v-if="error">Credenciales incorrectas</span>
      </div>

      <div class="flex align-items-center justify-content-between mb-6">
        <div class="flex align-items-center">
          <Checkbox
            id="rememberme1"
            :binary="true"
            v-model="rememberMe"
            class="mr-2"
          ></Checkbox>
          <label for="rememberme1">Recuérdame</label>
        </div>
        <router-link
          to="/recovery"
          class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
          >¿Has olvidado la contraseña?</router-link
        >
      </div>

      <Button
        label="Sign In"
        icon="pi pi-user"
        @click="submitForm(email, password, rememberMe)"
        class="w-full"
      ></Button>
    </div>
  </div>
</template>

<script>
import MerchantService from '../service/MerchantService';

export default {
  data() {
    return {
      email: '',
      password: '',
      rememberMe: false,
      merchantService: null,
      error: '',
      publicPath: process.env.BASE_URL,
    };
  },
  created() {
    this.merchantService = new MerchantService();
  },
  methods: {
    async submitForm(email, password, rememberMe) {
      if (email && password) {
        try {
          const user = await this.merchantService.login(
            email,
            password,
            rememberMe
          );
          this.$store.commit('setUser', { user });
          const redirectTo = this.$route.query.redirectFrom ?? '/';
          this.$router.push(redirectTo);
        } catch (e) {
          this.error = e.message;
        }
      } else {
        console.warn('Invalid login event payload!');
      }
    },
  },
};
</script>

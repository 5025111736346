<template>
  <div class="grid formgrid mt-4">
    <div class="col-12 mb-2 lg:col-4">
      <div class="form-field p-float-label">
        <InputText
          :disabled="!isEdit && !!fieldData"
          type="text"
          v-model="name"
          aria-label="Nombre del campo"
        ></InputText>
        <label for="field_label">Nombre del campo</label>
      </div>
      <small class="p-invalid" v-if="submitted && !name">
        El nombre es obligatorio.
      </small>
      <small class="p-invalid" v-if="duplicatedName">
        Ya existe un campo personalizado con este nombre.
      </small>
    </div>
    <div class="col-12 mb-2 lg:col-4">
      <div class="form-field p-float-label">
        <Dropdown
          :modelValue="fieldType"
          :options="getFieldTypes"
          @change="onFieldTypeChange($event)"
          optionLabel="label"
          placeholder="Tipo de campo"
          :disabled="!isEdit && !!dropOption"
          id="field_type"
        />
      </div>
      <small v-if="submitted && !fieldType">
        El tipo de campo es obligatorio.
      </small>
    </div>
    <div class="col-12 mb-2 lg:col-4">
      <div class="form-field p-float-label">
        <InputText
          v-model="label"
          :disabled="!isEdit && !!fieldData"
          type="text"
          aria-label="Etiqueta"
          id="field_label"
        ></InputText>
        <label for="field_label">Etiqueta</label>
      </div>
      <small v-if="submitted && !label">La etiqueta es obligatoria</small>
    </div>

    <template v-if="fieldType">
      <div class="col-12" />
      <div
        class="col-12 mb-2 lg:col-4 form-field"
        v-for="field in getFieldTypeInputs(fieldType)"
        :key="field.key"
      >
        <span v-if="field.type === 'stringList'" class="p-float-label">
          <div class="col-12 mb-2 lg:col-4 form-field">
            <Chip
              class="mb-2"
              :removable="!!isEdit || !fieldData"
              v-for="option in customFields[field.key]"
              @remove="removeDropdownOption(field.key, option)"
              :label="option"
              :key="option"
            />
            <div class="p-inputgroup" v-if="!!isEdit || !fieldData">
              <InputText
                :disabled="!isEdit && !!fieldData"
                type="text"
                id="dropdown_option"
                aria-label="Nueva opción"
                placeholder="Nueva opción"
                v-model="dropOption"
              ></InputText>
              <Button
                icon="pi pi-plus"
                aria-label="Añadir opción"
                @click="addDropdownOption(field.key)"
              />
            </div>
            <small
              v-if="
                submitted &&
                formFieldTypes.DROPDOWN.inputs[0].default.length === 0
              "
            >
              Debe de haber mínimo una opción
            </small>
          </div>
        </span>
        <span v-else-if="field.type === 'textArea'" class="p-float-label mt-4">
          <Textarea
            :disabled="!isEdit && !!fieldData"
            :id="field.key"
            :modelValue="customFields[field.key]"
            @input="inputChange(field.key, $event)"
          />
          <label :for="field.key">{{ field.label }}</label>
        </span>
        <span v-else-if="field.type === 'text'" class="p-float-label mt-4">
          <InputText
            :disabled="!isEdit && !!fieldData"
            :key="field.key"
            :modelValue="customFields[field.key]"
            @input="inputChange(field.key, $event)"
          />
          <label :for="field.key">{{ field.label }}</label>
        </span>
        <span v-else-if="field.type === 'calendar'" class="p-float-label mt-4">
          <Calendar
            :disabled="!isEdit && !!fieldData"
            :showIcon="true"
            selectionMode="single"
            v-model="customFields[field.key]"
          />
          <label :for="field.key">{{ field.label }}</label>
        </span>
      </div>
      <div class="col-12" />
    </template>

    <div class="col-12 mb-2 lg:col-4 form-field">
      <span class="field-checkbox">
        <label class="mr-2" for="is_required">Obligatorio</label>
        <Checkbox
          id="is_required"
          :disabled="!isEdit && !!fieldData"
          v-model="required"
          :binary="true"
        />
      </span>
    </div>
  </div>
  <div
    v-if="!fieldData"
    class="flex col-12 justify-content-end"
    style="gap: 1em"
  >
    <Button
      label="Cancelar"
      icon="pi pi-times"
      class="p-button-secondary w-max"
      @click="hideDialog"
    />
    <Button
      label="Crear"
      class="w-max"
      icon="pi pi-check"
      @click="onSaveClick"
    />
  </div>
  <div class="w-max col-12" v-else>
    <template v-if="isEdit">
      <Button
        icon="pi pi-save"
        aria-label="Guardar campo"
        class="mr-2"
        @click="onSaveClick"
      />
      <Button
        icon="pi pi-undo"
        aria-label="Deshacer cambio"
        class="mr-2 p-button-secondary"
        @click="onUndoClick"
      />
    </template>
    <template v-else>
      <Button
        icon="pi pi-pencil"
        aria-label="Editar campo"
        class="mr-2"
        @click="onEditClick"
      />
      <Button
        icon="pi pi-trash"
        @click="onRemoveClick"
        class="p-button-danger"
        aria-label="Eliminar campo"
      />
    </template>
  </div>
</template>

<script>
import { parseDateFromApi } from '../../utils/dates';
export default {
  emits: ['toggle', 'remove', 'save'],
  props: ['fieldData', 'isEdit', 'customFieldNames'],
  mounted() {
    this.populateFields(this.fieldData);
  },
  data() {
    return {
      organizationService: null,
      dropOption: '',
      name: '',
      label: '',
      duplicatedName: false,
      required: false,
      fieldType: null,
      customFields: null,
      formFieldTypes: {
        TEXT: {
          type: 'TEXT',
          label: 'Texto',
          inputs: [
            {
              label: 'Texto inicial',
              key: 'initialText',
              type: 'textArea',
              default: '',
            },
          ],
        },
        DROPDOWN: {
          type: 'DROPDOWN',
          label: 'Desplegable',
          inputs: [
            {
              label: 'Opciones del desplegable',
              key: 'dropdownOptions',
              type: 'stringList',
              default: [],
            },
            {
              label: 'Opción por defecto',
              key: 'defaultOption',
              type: 'string',
              default: '',
            },
          ],
        },
        TEXTAREA: {
          type: 'TEXTAREA',
          label: 'Área de texto',
          inputs: [
            {
              label: 'Texto inicial',
              key: 'initialText',
              type: 'text',
              default: '',
            },
          ],
        },
        CALENDAR: {
          type: 'CALENDAR',
          label: 'Calendario',
          inputs: [
            {
              label: 'Fecha de inicio',
              key: 'startDate',
              type: 'calendar',
              default: null,
            },
            {
              label: 'Fecha de fin',
              key: 'endDate',
              type: 'calendar',
              default: null,
            },
          ],
        },
      },
      submitted: false,
    };
  },
  watch: {
    name() {
      if (!this.customFieldNames) return;
      this.duplicatedName = this.customFieldNames.some(
        (fieldName) => fieldName === this.name
      );
    },
  },
  methods: {
    resetEdit() {
      this.populateFields(this.fieldData);
    },
    populateFields(fieldData) {
      if (!fieldData) return;
      const { name, label, required, type, ...customFields } = fieldData;
      this.name = name;
      this.label = label;
      this.required = required;
      this.fieldType = this.getFieldTypes.find(
        (fieldType) => fieldType.code === type
      );
      this.customFields = customFields;
      if (this.fieldType.code === 'CALENDAR') {
        if (customFields.startDate)
          this.customFields.startDate = parseDateFromApi(
            this?.customFields?.startDate
          );
        if (customFields.endDate)
          this.customFields.endDate = parseDateFromApi(
            this?.customFields?.endDate
          );
      }
    },
    hideDialog() {
      this.$emit('toggle');
    },
    onUndoClick() {
      this.populateFields(this.fieldData);
      this.$emit('toggle');
    },
    onSaveClick() {
      this.submitted = true;
      if (!this.name) {
        document.getElementById('field_name')?.focus?.();
        return;
      }
      if (!this.fieldType) {
        document.getElementById('field_type')?.focus?.();
        return;
      }
      if (!this.label) {
        document.getElementById('field_label')?.focus?.();
        return;
      }
      if (this.fieldType.code === 'DROPDOWN') {
        if (this.customFields['dropdownOptions'].length === 0) {
          document.getElementById('dropdown_option')?.focus?.();
          return;
        }
      }

      const customField = {
        type: this.fieldType.code,
        name: this.name,
        required: this.required,
        label: this.label,
        ...this.customFields,
      };
      this.$emit('save', customField);
    },
    onEditClick() {
      this.$emit('toggle');
    },
    onRemoveClick() {
      this.$emit('remove');
    },
    inputChange(fieldKey, $event) {
      this.customFields[fieldKey] = $event.target.value;
    },
    addDropdownOption(fieldKey) {
      const newOption = this.dropOption.trim();
      if (newOption.length === 0) {
        this.$toast.add({
          severity: 'error',
          summary: 'La opción no puede estar vacía',
          detail: 'La opción debe contener al menos un carácter',
          life: 3000,
        });
        return;
      }
      if (this.customFields[fieldKey].includes(newOption)) {
        this.$toast.add({
          severity: 'error',
          summary: 'La opción ya existe',
          detail: 'La opción debe ser única en el desplegable',
          life: 3000,
        });
        return;
      }
      this.customFields[fieldKey].push(this.dropOption);
      this.dropOption = '';
    },
    removeDropdownOption(fieldKey, option) {
      this.customFields[fieldKey].splice(
        this.customFields[fieldKey].indexOf(option),
        1
      );
    },
    onFieldTypeChange($event) {
      const { value } = $event;
      this.customFields = this.getFieldTypeFormDefaults[value?.code];
      this.dropOption = '';
      this.fieldType = value;
    },
    getFieldTypeInputs(type) {
      return this.formFieldTypes?.[type?.code ?? type]?.inputs ?? [];
    },
  },
  computed: {
    getFieldTypes() {
      return Object.values(this.formFieldTypes).map(({ type, label }) => ({
        code: type,
        label,
      }));
    },
    getFieldTypeFormDefaults() {
      let defaults = {};
      Object.entries(this.formFieldTypes).forEach(([key, type]) => {
        defaults[key] = {};
        type.inputs.forEach(
          (input) => (defaults[key][input.key] = input.default)
        );
      });
      return defaults;
    },
  },
};
</script>

<style scoped></style>

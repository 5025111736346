import { createStore } from 'vuex';
import { TRIAL_STATE } from '../constants/constants';
import MerchantService from '../service/MerchantService';

const merchantService = new MerchantService();

const authStore = createStore({
  state() {
    return {
      authenticated: false,
      user: null,
      isInitialized: false,
    };
  },
  mutations: {
    setUser(state, { user }) {
      if (!user) {
        state.authenticated = false;
      } else {
        state.authenticated = true;
        state.user = user;
      }
      state.isInitialized = true;
    },
    cancelPlan(state) {
      if (!state.user) return;
      state.user.pricingStatus = 'CANCELLED';
      state.user.trialState = TRIAL_STATE.INACTIVE;
    },
    logout(state) {
      merchantService.logout();
      state.authenticated = false;
      state.user = null;
    },
  },
  actions: {
    async initialize(context) {
      try {
        const user = await merchantService.me();
        context.commit('setUser', {
          user,
        });
        return true;
      } catch (error) {
        context.commit('setUser', {
          user: null,
        });
        return false;
      }
    },
  },
});

export default authStore;

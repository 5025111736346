import { setToken, removeToken } from '../utils/auth';
import api from '../api/Acutame';

export default class MerchantService {
  async createMerchant(merchant) {
    return api.createMerchant(merchant);
  }

  /**
   * 
   * @param {string} email 
   * @returns {Promise<boolean>}
   */
  async isEmailAvailable(email) {
    const response = await api.isEmailAvailable(email);
    return response.available;
  }

  async login(email, password, stayLogged = true) {
    const token = await api.login(email, password);
    removeToken();
    setToken(token, stayLogged);
    return this.me();
  }

  me() {
    return api.me();
  }

  logout() {
    removeToken();
  }

  async sendRecoveryEmail(email) {
    return api.sendRecoveryEmail(email);
  }

  async changePassword(email, newPassword, code) {
    return api.changePassword(email, newPassword, code);
  }
}
